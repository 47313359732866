import htmx from 'htmx.org'

function handleFaqButtonClick(e: MouseEvent) {
  if (!e.target) {
    return
  }

  const button = (e.target as HTMLElement).closest('button')
  const plusSvg = button?.querySelector('.plus')
  const minusSvg = button?.querySelector('.minus')

  if (!button || !plusSvg || !minusSvg) {
    return
  }

  const targetId = button.getAttribute('aria-controls')

  if (!targetId) {
    return
  }

  const isExpanded = button.getAttribute('aria-expanded') === 'true'
  const targetEl = document.getElementById(targetId)

  if (!targetEl) {
    return
  }

  targetEl.classList.toggle('hidden')
  plusSvg.classList.toggle('hidden')
  minusSvg.classList.toggle('hidden')
  button.setAttribute('aria-expanded', (!isExpanded).toString())
}

function handleCountrySelectorClick(e: MouseEvent) {
  const deFees = document.getElementById('de-fees')
  const atFees = document.getElementById('at-fees')

  if (!e.target) {
    return
  }

  if (deFees && atFees) {
    deFees.classList.toggle('hidden')
    atFees.classList.toggle('hidden')
  }

  const label = (e.target as HTMLElement).closest('label')

  if (!label) {
    return
  }

  label.classList.add('bg-me-purple-600', 'text-white')
  label.classList.remove('text-gray-500')

  if (label.nextElementSibling) {
    label.nextElementSibling.classList.remove('bg-me-purple-600', 'text-white')
    label.nextElementSibling.classList.add('text-gray-500')
  }

  if (label.previousElementSibling) {
    label.previousElementSibling.classList.remove('bg-me-purple-600', 'text-white')
    label.previousElementSibling.classList.add('text-gray-500')
  }
}

function listenForFaq3Hash() {
  if (window.location.hash === '#faq-3-container') {
    const faq3Button = document.querySelector('button[aria-controls="faq-3"]') as HTMLElement

    if (faq3Button) {
      faq3Button.click()
    }
  }
}

htmx.onLoad(() => {
  const pricingFaq = document.getElementById('pricing-faq')
  const countrySelector = document.getElementById('country-selector')

  if (pricingFaq) {
    const buttons = pricingFaq.querySelectorAll('button')

    for (const button of buttons) {
      if (button) {
        button.addEventListener('click', handleFaqButtonClick)
      }
    }
  }

  if (countrySelector) {
    const inputs = countrySelector.querySelectorAll('input')

    if (inputs) {
      for (const input of inputs) {
        input.addEventListener('click', handleCountrySelectorClick)
      }
    }
  }

  listenForFaq3Hash()
})

window.addEventListener('hashchange', listenForFaq3Hash, false)
